import './src/theme/destyle.css'
import './src/theme/global.css'

import { ParallaxProvider } from 'react-scroll-parallax'

import { ThemeProvider } from './src/theme'
import { Transition } from './src/utils/transition'

const transitionDelay = 500

export const wrapPageElement = ({ element, props }) => {
  return (
    <ThemeProvider>
      <Transition {...props}>
        <ParallaxProvider>{element}</ParallaxProvider>
      </Transition>
    </ThemeProvider>
  )
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  window.setTimeout(() => window.scrollTo(...[0, 0]), transitionDelay)

  return false
}
