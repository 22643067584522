exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dev-404-page-tsx": () => import("./../../../src/pages/dev-404-page.tsx" /* webpackChunkName: "component---src-pages-dev-404-page-tsx" */),
  "component---src-templates-amenities-page-index-tsx": () => import("./../../../src/templates/AmenitiesPage/index.tsx" /* webpackChunkName: "component---src-templates-amenities-page-index-tsx" */),
  "component---src-templates-article-page-index-tsx": () => import("./../../../src/templates/ArticlePage/index.tsx" /* webpackChunkName: "component---src-templates-article-page-index-tsx" */),
  "component---src-templates-bar-page-index-tsx": () => import("./../../../src/templates/BarPage/index.tsx" /* webpackChunkName: "component---src-templates-bar-page-index-tsx" */),
  "component---src-templates-contacts-page-index-tsx": () => import("./../../../src/templates/ContactsPage/index.tsx" /* webpackChunkName: "component---src-templates-contacts-page-index-tsx" */),
  "component---src-templates-gallery-page-index-tsx": () => import("./../../../src/templates/GalleryPage/index.tsx" /* webpackChunkName: "component---src-templates-gallery-page-index-tsx" */),
  "component---src-templates-gym-page-index-tsx": () => import("./../../../src/templates/GymPage/index.tsx" /* webpackChunkName: "component---src-templates-gym-page-index-tsx" */),
  "component---src-templates-history-page-index-tsx": () => import("./../../../src/templates/HistoryPage/index.tsx" /* webpackChunkName: "component---src-templates-history-page-index-tsx" */),
  "component---src-templates-home-page-index-tsx": () => import("./../../../src/templates/HomePage/index.tsx" /* webpackChunkName: "component---src-templates-home-page-index-tsx" */),
  "component---src-templates-info-page-index-tsx": () => import("./../../../src/templates/InfoPage/index.tsx" /* webpackChunkName: "component---src-templates-info-page-index-tsx" */),
  "component---src-templates-location-page-index-tsx": () => import("./../../../src/templates/LocationPage/index.tsx" /* webpackChunkName: "component---src-templates-location-page-index-tsx" */),
  "component---src-templates-login-page-index-tsx": () => import("./../../../src/templates/LoginPage/index.tsx" /* webpackChunkName: "component---src-templates-login-page-index-tsx" */),
  "component---src-templates-magazine-page-index-tsx": () => import("./../../../src/templates/MagazinePage/index.tsx" /* webpackChunkName: "component---src-templates-magazine-page-index-tsx" */),
  "component---src-templates-meeting-page-index-tsx": () => import("./../../../src/templates/MeetingPage/index.tsx" /* webpackChunkName: "component---src-templates-meeting-page-index-tsx" */),
  "component---src-templates-offer-page-index-tsx": () => import("./../../../src/templates/OfferPage/index.tsx" /* webpackChunkName: "component---src-templates-offer-page-index-tsx" */),
  "component---src-templates-offers-page-index-tsx": () => import("./../../../src/templates/OffersPage/index.tsx" /* webpackChunkName: "component---src-templates-offers-page-index-tsx" */),
  "component---src-templates-policy-page-index-tsx": () => import("./../../../src/templates/PolicyPage/index.tsx" /* webpackChunkName: "component---src-templates-policy-page-index-tsx" */),
  "component---src-templates-press-page-index-tsx": () => import("./../../../src/templates/PressPage/index.tsx" /* webpackChunkName: "component---src-templates-press-page-index-tsx" */),
  "component---src-templates-restaurant-page-index-tsx": () => import("./../../../src/templates/RestaurantPage/index.tsx" /* webpackChunkName: "component---src-templates-restaurant-page-index-tsx" */),
  "component---src-templates-rooms-page-index-tsx": () => import("./../../../src/templates/RoomsPage/index.tsx" /* webpackChunkName: "component---src-templates-rooms-page-index-tsx" */),
  "component---src-templates-staff-page-index-tsx": () => import("./../../../src/templates/StaffPage/index.tsx" /* webpackChunkName: "component---src-templates-staff-page-index-tsx" */),
  "component---src-templates-terrace-page-index-tsx": () => import("./../../../src/templates/TerracePage/index.tsx" /* webpackChunkName: "component---src-templates-terrace-page-index-tsx" */),
  "component---src-templates-wellness-page-index-tsx": () => import("./../../../src/templates/WellnessPage/index.tsx" /* webpackChunkName: "component---src-templates-wellness-page-index-tsx" */)
}

