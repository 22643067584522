import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme } from 'app/theme'
import { AnimatePresence, motion } from 'framer-motion'
import React, { memo, ReactNode, useEffect } from 'react'

const duration = 1

interface Props {
  children: ReactNode
  location: any
}

export const Transition = memo(function Transition({
  children,
  location,
}: Props) {
  useEffect(() => {
    history.scrollRestoration = 'manual'
  }, [])

  return (
    <AnimatePresence mode="wait">
      <Main key={location.pathname}>
        <SlideIn
          initial={{ scaleY: 0 }}
          animate={{ scaleY: 0 }}
          exit={{ scaleY: 1 }}
          transition={{ duration, ease: [0.22, 1, 0.36, 1] }}
        />
        <SlideOut
          initial={{ scaleY: 1 }}
          animate={{ scaleY: 0 }}
          exit={{ scaleY: 0 }}
          transition={{ duration, ease: [0.22, 1, 0.36, 1] }}
        />

        {children}
      </Main>
    </AnimatePresence>
  )
})

const Main = styled(motion.main)``

const Style = css`
  width: 100%;
  height: 100vh;
  background: ${theme.colors.variants.primaryDark};
  position: fixed;
  top: 0;
  left: 0;
  transform-origin: bottom;
  z-index: 10000;
`

const SlideIn = styled(motion.aside)`
  ${Style}

  transform-origin: bottom;
`

const SlideOut = styled(motion.aside)`
  ${Style}

  transform-origin: top;
`
